@import "~bulma/sass/utilities/functions";

$cascading-white: #f6f6f6;
$cascading-white-invert: findColorInvert($cascading-white);
$frost-grey: #838383;
$frost-grey-invert: findColorInvert($frost-grey);
$shadowed-steel: #4b4b4b;
$shadowed-steel-invert: findColorInvert($shadowed-steel);

$custom-colors: (
  "cascading-white": (
    $cascading-white,
    $cascading-white-invert,
  ),
  "frost-grey": (
    $frost-grey,
    $frost-grey-invert,
  ),
  "shadowed-steel": (
    $shadowed-steel,
    $shadowed-steel-invert,
  ),
);
