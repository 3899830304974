// Customize your Bulma styles here!
// @import "./bulmaPadding.scss";

@import "./bulmaColors.scss";
@import "~bulma/sass/utilities/initial-variables";
@import "~bulma/sass/utilities/functions";

$size-1: 50px;
$size-3: 36px;
$size-4: 24px;
$size-5: 20px;
$size-6: 14px;
$size-7: 12px;
$body-size: 16px;

$weight-bold: 900;
$button-border-color: transparent;

@import "~bulma/sass/utilities/derived-variables";
