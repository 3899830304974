@import "./bulma.scss";

body {
  font-family: "Greycliff";
  font-weight: 700;
  color: #242424;
}

@font-face {
  font-family: "ArcadeClassic";
  src: url("./fonts/ArcadeClassic.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Greycliff";
  src: url("./fonts/GreycliffCF-ExtraBold.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Greycliff";
  src: url("./fonts/GreycliffCF-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Greycliff";
  src: url("./fonts/GreycliffCF-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Greycliff";
  src: url("./fonts/GreycliffCF-Light.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}
