.build-a-bot {
  background-color: black;
  min-height: 100vh;
  color: white;

  &__loader-bar {
    margin: 0 auto;
    max-width: 500px;
  }

  &__composing-bar {
    margin: 0 auto;
    max-width: 200px;
  }

  &__img {
    position: absolute;
  }

  &__navbar {
    padding: 30px;
    border-bottom: 7px solid #4cff9f;
  }

  &__connect-button {
    border: 4px solid #4cff9f;
    color: #4cff9f;
    border-radius: 2px;
  }

  &__container {
    &--inventory {
      max-width: 500px;
    }
    &--composed {
      width: 400px;
    }
    background-color: #4cff9f;
    min-height: 300px;
    min-width: 250px;
    color: black;
  }

  &__composed-part {
    max-width: 300px;
    &--baby {
      top: 50px;
      left: 160px;
    }
  }

  &__selected-nft {
    margin: 0 auto;
    margin-top: 20px;
    max-width: 300px;
  }
}
