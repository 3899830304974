.rarity-tool {
  font-family: "ArcadeClassic";

  .bot-card ~ .bot-card {
    margin-top: 40px !important;
  }

  &__bot-image {
    &--empty {
      min-height: 170px;
      max-width: 100%;
      background-color: white;
    }
    border: 4px solid black;
    width: 240px;
    min-width: 150px;
  }

  &__bot-name {
    height: 80px;
  }

  &__card {
    width: 200px;
    height: 200px;
  }

  &__header {
    border: 4px solid black;
  }

  &__container {
    background: url("../images/backgrounds/tool-background.png") no-repeat
      center center fixed;
    min-height: calc(100vh - 67px);
  }

  &__error-toast {
    font-family: "ArcadeClassic", "Greycliff";

    &--slide-in {
      letter-spacing: 0.15em;
      animation: typing 0.5s steps(40, end);
      @keyframes typing {
        from {
          width: 0;
        }
        to {
          width: 100%;
        }
      }

      /* The typewriter cursor effect */
      @keyframes blink-caret {
        from,
        to {
          border-color: transparent;
        }
        50% {
          border-color: orange;
        }
      }
    }
  }

  &__icon {
    &--unknown {
      background: linear-gradient(
        134.24deg,
        #ff0000 14.78%,
        #ed9543 38.23%,
        #69ff9c 58.49%,
        #2400ff 91.33%
      );
    }
    &--common {
      background: linear-gradient(135deg, #ffffff 0%, #c9c9c9 100%);
    }
    &--rare {
      background: linear-gradient(130.68deg, #45ff8f 0%, #4dfff4 108.78%);
    }
    &--epic {
      background: linear-gradient(130.68deg, #ffc0de 0%, #bd00ff 108.78%);
    }
    &--legendary {
      background: linear-gradient(119.07deg, #ffc876 19.21%, #ff0000 134.25%);
    }
    width: 20px;
    height: 20px;
    border-radius: 100px;
    position: absolute;
    top: 10px;
    right: -11px;
    border: 3px solid black;
  }

  &__placeholder-img {
    width: 240px;
    height: 240px;
    border: 3px solid black;
  }

  &__lightning {
    width: 50px;
  }

  &__rick {
    &--angry {
      width: 170px !important;
    }
    &--text {
      letter-spacing: 0.2em;
    }
    width: 50px;
  }

  &__search {
    border: 4px solid black;
    border-radius: 3px;
    height: 34px;
    font-size: 19px;
    padding-left: 30px;
  }

  input[type="number"]::-webkit-inner-spin-button {
    opacity: 1;
  }

  &__search-icon {
    left: 9px;
    top: 8px;
  }

  &__trait {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 220px;
    height: 52px;
    position: relative;
    margin-bottom: 10px;
    border-radius: 2px;
    &--borderless {
      border-top: 4px solid #000;
      border-bottom: 4px solid #000;
    }

    &--bordered {
      border: 4px solid #000;
    }

    &--background {
      background-color: #232323;
      width: 140px;
    }
  }
}

.ricky-toaster {
  .bp3-toast {
    border: 6px solid black;
    height: 200px !important;
  }
}
